.red-text {
  color: red;
  font-size: 16px;
}

.page-content-container {
  padding: 0px 40px;
}

.normal-button {
  padding: 5px 10px;
  border-radius: 5px;
  border: unset;
}

.tabs-page-container {
  margin: 20px 0px 40px 0px;
  display: flex;
  align-items: center;
}

.active-page-tab {
  background-color: var(--primary);
  color: var(--btn-text) !important;
  border-radius: 5px;
  padding: 5px 10px !important;
}

.logout-container {
  display: flex;
  gap: 10px;
  padding: 5px 10px;
}

.logout-container:hover {
  background-color: var(--primary);
  color: var(--btn-text);
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .page-content-container {
    padding: 0px 10px;
  }
}
