.calender-container {
  width: 100%;
}
.month-box {
  width: 33%;
  min-width: 400px;
  display: inline-block;
}

.week-line {
  width: 100%;
  display: inline-block;
}

.date-box {
  width: 12.5%;
  min-width: 50px;
  display: inline-block;
}

.blue-text {
  color: red;
}

.calendar-valid-dates {
  background-color: #66fc00;
  font-weight: bold;
}

.calendar-invalid-dates {
  background-color: #ff0800;
  font-weight: bold;
}

.stream-information-header {
  width: 40%;
  height: 100%;
  border: solid 1px gray;
  margin: 0px;
  padding: 10px;
  font-weight: bold;
}

.no-stream-information-message {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
