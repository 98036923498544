body {
  margin: 0;
  font-family: "Times New Roman", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary: #9147ff;
  --secondary: black;
  --btn-text: white;
  --btn-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --btn-hover-bg: white;
  --btn-hover-text: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary) !important;
}

p {
  color: var(--secondary) !important;
}

button {
  background-color: var(--primary) !important;
  color: var(--btn-text) !important;
}

button:hover {
  box-shadow: var(--btn-shadow) !important;
  background-color: var(--btn-hover-bg) !important;
  color: var(--btn-hover-text) !important;
  cursor: pointer;
}
